<template>
  <BasicInformationCard title="Te plannen en geplande taken" :promises.sync="promises" custom-icon="mdi-calendar-plus">
    <template v-slot:content>
      <overview :items="plannedTasks" :headers="headers" :filters="filters" :succesFunction="fetchPlannedTasks"
        :info="getGenerationInformation()" :showCreateButton="showGenerateTasksActions" :disable-pagination="false"
        :addItemRoute="getGenerationRoute" addBtnText="Genereer extra taken"
        local-storage-prefix="taskdetail#plannedTask">
        <template v-slot:item.planningPeriod="{ item }">
          <span>{{ applyDateFormat(item.scheduleFrom) }} - {{ applyDateFormat(item.scheduleTo) }}</span>
        </template>
        <template v-slot:item.scheduledPeriod="{ item }">
          <span>{{ applyDateTimeFormat(item.assignedDetails?.start) }} - {{ applyTimeFormat(item.assignedDetails?.end)
          }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="getPlannedTaskStatusColor(item.status)" label class="max-h-36px">
            {{ translatePlannedTaskStatus(item.status) }}
          </v-chip>
        </template>
        <template v-slot:extra-actions="{ item }">
          <v-icon :disabled="!item.assignedDetails"  class="mr-2" @click.stop.prevent="unschedule(item.id)">
            mdi-account-cancel
          </v-icon>
          <v-icon :disabled="item.status !== 'OPEN'"  class="mr-2" @click.stop.prevent="cancel(item.id)">
            mdi-cancel
          </v-icon>
        </template>
      </overview>
    </template>
  </BasicInformationCard>
</template>

<script>
import Overview from "@/components/shared/Overview"
import BasicInformationCard from "@/components/shared/BasicInformationCard.vue"
import { translatePlannedTaskStatus, getPlannedTaskStatusColor } from "@/shared/utils/translateUtils"
import { applyDateFormat, applyDateTimeFormat, applyTimeFormat } from "@/shared/utils/dateUtils"
import routeNames from "@/router/RouteNames"
import TaskFrequencyType from '../../shared/enums/taskFrequencyType'

export default {
  name: "TaskDetailPlannedTaskListComponent",
  components: {
    BasicInformationCard,
    Overview,
  },
  props: {
    taskId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      routeNames,
      headers: [
        {
          text: "Planningsperiode",
          value: 'planningPeriod',
          cellClass: 'notranslate'
        },
        {
          text: "Toegewezen uren",
          value: 'scheduledPeriod',
          cellClass: 'notranslate'
        },
        {
          text: "Toegewezen persoon",
          value: 'assignedDetails.userName',
          cellClass: 'notranslate'
        },
        {
          text: 'Status',
          value: 'status'
        },
      ],
      filters: [
        {
          title: "Status",
          options: [
            {
              id: 'status#open',
              label: 'Open',
              active: false,
              predicate: (plannedTask) => plannedTask.status && plannedTask.status === "OPEN"
            },
            {
              id: 'status#cancelled',
              label: 'Geannuleerd',
              active: false,
              predicate: (plannedTask) => plannedTask.status && plannedTask.status === "CANCELLED"
            },
            {
              id: 'status#done',
              label: 'Gedaan',
              active: false,
              predicate: (plannedTask) => plannedTask.status && plannedTask.status === "DONE"
            },
          ]
        }
      ],
      plannedTasks: [],
      promises: [this.fetchPlannedTasks(), this.getExpectedPlannedTaskCount(), this.fetchRecurrenceDetails()],
      expectedCount: null,
      recurrenceDetails: null,
    }
  },
  methods: {
    applyTimeFormat,
    applyDateFormat,
    applyDateTimeFormat,
    translatePlannedTaskStatus,
    getPlannedTaskStatusColor,
    fetchPlannedTasks() {
      return this.$store.dispatch('plannedTasksModule/fetchPlannedTasksForTask', this.taskId)
        .then((result) => this.plannedTasks = result)
    },
    fetchRecurrenceDetails() {
      this.$store.dispatch("tasksModule/fetchTaskRecurrence", { clientId: this.clientId, projectId: this.projectId, taskId: this.taskId })
        .then(result => this.recurrenceDetails = result)
    },
    getGenerationInformation() {
      if (this.expectedCount && this.expectedCount > 0) {
        return this.plannedTasks.length + ' van ' + this.expectedCount + ' geplande taken zijn gegenereerd'
      }
      return null;
    },
    getExpectedPlannedTaskCount() {
      return this.$store.dispatch('tasksModule/fetchExpectedPlannedTaskCount', this.taskId)
        .then((result) => this.expectedCount = result)
    },

    unschedule(id) {
      return this.$store.dispatch('plannedTasksModule/removeAssignmentDetails', id)   
        .then(() => {
          this.fetchPlannedTasks()
      })   
    },
    cancel(id) {
      return this.$store.dispatch('plannedTasksModule/cancelPlannedTask', id)   
        .then(() => {
          this.fetchPlannedTasks()
      })
    },
  },
  computed: { 
    getGenerationRoute() {
      return { name: routeNames.TASK_DETAIL_GENERATE, params: { clientId: this.clientId, projectId: this.projectId, taskId: this.taskId } }
    },
    showGenerateTasksActions() {
      return this.recurrenceDetails !== null 
                && this.recurrenceDetails !== undefined 
                && this.recurrenceDetails.taskFrequency !== TaskFrequencyType.ONE_TIME
                && this.plannedTasks.length < this.expectedCount
    },
  },
}
</script>
